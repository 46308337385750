.fs-1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

.fs-3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.marginTop50px {
    margin-top: 50px;
}

.marginTop10px {
    margin-top: 10px;
}